import React, { useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { SelectButton, useLocalStorage } from "@ruby-labs/ruby-web-common";

import { analyticEventValueGetter } from "../../analytic_event_value_getter";
import { BaseContext } from "../../../../helpers/context/base.context";
import { GenericInstanceCTX } from "../../../../store/generic_store";

export const ButtonSelectLogic = ({ className, path, destinationPath: destination, event,  ...buttonProps }: any) => {
    let history = useHistory();
    const { analytic, destinationService } = useContext(BaseContext);
    const { pageValue, setPageValue } = useLocalStorage({ key: path, defaultValue: '' })
    const { startPreloadPage } = useContext(GenericInstanceCTX)
    const destinationPath = destinationService.parse({destination})
    const props = { ...buttonProps };
    props.label = destinationService.parse({ destination: props.label });
    props.value = destinationService.parse({ destination: props.value });

    //@ts-ignore
    useEffect(() => startPreloadPage(destinationPath),[startPreloadPage, destinationPath])

    const buttonClicked = () => {
        const value = props.value || props.label;
        // @ts-ignore
        const getter = analyticEventValueGetter[path];

        setPageValue(value);

        if(event) analytic?.event(event, {
            value: getter ? getter(value) : value
        });

        setTimeout(() => history.push(destinationPath), 100);
    }

    return (
        <div className={className}>
            <SelectButton
                centered={true}
                selected={props.value === pageValue}
                onClick={buttonClicked}
                {...props}
            />
        </div>
    );
}
