import React, { useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { PrimaryButton, useLocalStorage } from "@ruby-labs/ruby-web-common";

import { FloatedButton } from "../../../floated_button";

import { analyticEventValueGetter } from "../../analytic_event_value_getter";
import { isDataInPageNotExist } from "../../../../utils/buttons_conditions";
import { BaseContext } from '../../../../helpers/context/base.context'
import { GenericInstanceCTX } from "../../../../store/generic_store";

export const ButtonBaseLogic = ({
    className,
    destinationPath:
    destination,
    path,
    alwaysAble,
    event,
    ...props
}: any) => {
    let history = useHistory();
    const { analytic, destinationService } = useContext(BaseContext);
    const { pageValue } = useLocalStorage({ key: path, defaultValue: '' });
    const { pageValue: pageError } = useLocalStorage({ key: `${path}_error`, defaultValue: '' });

    const { startPreloadPage }: any = useContext(GenericInstanceCTX)
    const destinationPath = destinationService.parse({destination})

    useEffect(() => {
        startPreloadPage(destinationPath);
    },[startPreloadPage, destinationPath])

    const isButtonDisabled = alwaysAble ? false : (isDataInPageNotExist(pageValue) || pageError)

    const buttonClicked = () => {
        // @ts-ignore
        const getter = analyticEventValueGetter[path];
        const eventValue = getter ? getter(pageValue) : { value: pageValue };

        if (event) analytic?.event(event, eventValue);

        history.push(destinationPath);
    };

    delete props.width;

    const { pathname } = window.location;
    const isFloatedButton = pathname === '/body-risk' || pathname === '/nutrition-donteat';

    return (
        <div className={className}>
            <FloatedButton floated={isFloatedButton}>
                <PrimaryButton
                    onClick={buttonClicked}
                    disabled={isButtonDisabled}
                    {...props}
                />
            </FloatedButton>
        </div>
    );
};
